/**
 * = Images
 */

.image-lg {
    height: 12rem;
}

.image-md{
    height: 5rem;
}

.image-sm {
    height: 3rem;
}

.img-thumbnail {
    border-width: $border-width-md;
    box-shadow: none;
}

.full-image{
    height:100%;
}

.img-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.gallery-feed{
    img{
        width: 20%;
        margin-right: .5rem;
        margin-bottom: .5rem;
        float: left;
    }
}

.video-poster{
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    a{
        position:absolute;
    }
}

@include media-breakpoint-up(sm){
    .effect-img-2 {
        position: absolute;
        right: 5rem;
        top: 19%;
        z-index: 2;
        margin: 0;
    }

    .effect-img-1, .effect-img-2 {
        margin: 0 0 3rem;
        width: 350px;
        height: auto;
    }
}

@include media-breakpoint-down(md){
    .effect-img-2{
        right:.425rem;
        top:0;
    }
}


// before&after photo effect

.image-container {
	position: relative;
	width: 100%;
	max-width: 100%;
	margin: 0em auto;

	img {
        display: block;
        width: 100%;
	}
}

.image-label {
	position: absolute;
	bottom: 0;
	right: 0;
	color: $secondary;
	padding: 1em;
	opacity: 0;
	@include transform(translateY(20px));
	-webkit-transition: -webkit-transform .3s $bounce-in-duration, opacity .3s $bounce-in-duration;
	-moz-transition: -moz-transform .3s $bounce-in-duration, opacity .3s $bounce-in-duration;
	transition: transform .3s $bounce-in-duration, opacity .3s $bounce-in-duration;

	&.is-hidden {
		visibility: hidden;
	}

	.is-visible & {
		opacity: 1;
		@include transform(translateY(0));
	}
}

.resize-img {
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 100%;
	overflow: hidden;
	/* Force Hardware Acceleration in WebKit */
	@include transform(translateZ(0));
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;

	img {
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		height: 100%;
		width: auto;
		max-width: none;
	}

	.image-label {
		right: auto;
		left: 0;
	}

	.is-visible & {
		width: 50%;

	}
}


.handle {
	position: absolute;
	height: 44px;
	width: 44px;
	/* center the element */
	left: 50%;
	top: 50%;
	margin-left: -22px;
	margin-top: -22px;
	border-radius: 50%;
	background: $primary url('../../assets/img/arrows.svg') no-repeat center center;
	cursor: move;
	box-shadow: 0 0 0 6px rgba($primary, .2), 0 0 10px rgba($primary, .6), inset 0 1px 0 rgba(#fff, .3);
	opacity: 0;
	@include transform(translate3d(0,0,0) scale(0));

	&.draggable {
		/* change background color when element is active */
		background-color: $primary;
	}

	.is-visible & {
		opacity: 1;
		@include transform(translate3d(0,0,0) scale(1));
		-webkit-transition: -webkit-transform .3s $bounce-in-duration, opacity 0s $bounce-in-duration;
		-moz-transition: -moz-transform .3s $bounce-in-duration, opacity 0s $bounce-in-duration;
		transition: transform .3s $bounce-in-duration, opacity 0s $bounce-in-duration;
	}
}