/**
 * = Custom forms
 */

.custom-control-label {
    // Background-color and (when enabled) gradient
    &:before {
        border: $custom-checkbox-indicator-border-width solid $custom-control-border-color;
        box-shadow: $custom-control-box-shadow;
        @if $enable-transitions {
            transition: $input-transition;
        } 
    }

    span {
        position: relative;
        top: 2px;
    }
} 

.custom-control-label {
    margin-bottom: 0;
}

.custom-control-input {
    &:active~.custom-control-label::before {
        border-color: $custom-control-indicator-active-border-color;
    }
}

// Custom File Input

.custom-file-label{
    border: $custom-control-border-width solid $custom-control-border-color;
    font-size: $input-font-size;
    color:$input-placeholder-color;
    padding: 0.725rem .65rem;
    &::after {
        height: 100%;
        padding: 0.725rem .65rem;
        background-color: $gray-100;
        border:0;
    }
}

.custom-file-input{
    &:not(:disabled):hover {
        cursor: pointer;
    
        & ~ .custom-file-label,
        & ~ .custom-file-label:before {
          border-color: $gray-300;
        }
    }
}


//Select
.custom-select{
    font-size: $input-btn-font-size;
    box-shadow: $input-box-shadow;

    &.item-sort:not(.input-group-btn), .item-sort[class*="col-"]{
        display: inline-block;
    }

    &:hover {
        cursor: pointer;
    }
}


