/**
 * = Shapes
 */

.pattern {
    overflow: hidden;
    z-index: 1;
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
    
    &.top {
        top: 0;
        @include transform(rotate(180deg));
    }

    &.bottom {
        bottom:0
    }

    svg {
        display: block;
        width: calc(100% + 1.3px);
        position: relative;
        left: 50%;
        @include transform(translateX(-50%))
    }
}


.organic-shape {
	&:not(.position-relative) {
		position: absolute;
    }
    
	svg {
		background: none !important;
    }
    
	&.top {
		top: 0;
    }
    
	&.left {
		left: 0;
    }
    
	&.right {
		right: 0;
    }
    
	&.middle-y {
		top: 50%;
    }
    
	&.middle-x {
		left: 50%;
    }
    
	&.bottom {
		bottom: 0;
    }
    
	@for $i from 2 through 5 {
		&.scale-#{$i}{
			svg{
				transform: scale(#{$i});
			}
		}
    }
}

// Icon (svg) organic-shapes fill colors
@each $color, $value in $theme-colors {
    .organic-shape-#{$color}{ 
        fill: rgba( $value, .3 );
    }
}

// Shape (svg) fill colors
@each $color, $value in $theme-colors {
    .fill-#{$color}  {
        fill: $value;
    }

    .stroke-#{$color}  {
        stroke: $value;
    }
}

// Shape colors
@each $color, $value in $theme-colors {
    .shape-#{$color}  {
        background-color: $value;
        .step-number{
            background-color:$value;
        }
    }
}

.organic-radius{
    border-radius:63% 37% 30% 70% / 50% 45% 55% 50%;
}

.avatar-sm {
    width: $avatar-sm-y;
    height: $avatar-sm-x;
}

.avatar-md {
    width: $avatar-md-y;
    height: $avatar-md-x;
}

.avatar-lg {
    width: $avatar-lg-y;
    height: $avatar-lg-x;
}

.color-shape {
    width: 7rem;
    height: 7rem;
}

.shape {
    position: absolute;
    pointer-events: none;
    transform: scale(2);
    &.bottom {
        right: 0;
        bottom: 0;
        left: 0;
    }
}

.shape-fluid > * {
    width: 100%;
    height: auto;
}

