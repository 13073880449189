/**
 * = Cards
 */

.card {
    position: relative;
    .card-header{
        background: transparent;
    }
    &.img-card{
        @include background-image(no-repeat, cover); 
    }
    &.card-article-wide{
        img{
            height: 100%;
        }
    }
    .listing-badge {
        bottom: 15px;
        right: 15px;
    }

    &.card-row {
  
        .card-body {
            @include media-breakpoint-up(md) {
                padding: $card-row-spacer-y $card-row-spacer-x;
            }
        }
    
        .card-meta {
            @include media-breakpoint-up(md) {
                padding-left: $card-row-spacer-x;
                padding-right: $card-row-spacer-x;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .card-group {
        flex-direction: column;
    }
}

.card-meta {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 $card-row-spacer-x $card-row-spacer-x;
}

.profile-page {
    .card-profile {
        margin-top: -150px;

        .card-profile-image {
            position: relative;

            img {
                max-width: 180px;
                @include border-radius($border-radius);
                transform: translate(-50%,-30%);
                position: absolute;
                left: 50%;
                transition: $transition-base;

                &:hover {
                    transform: translate(-50%, -33%);
                }
            }
        }

        .card-profile-stats {
            padding: 1rem 0;

            > div {
                text-align: center;
                margin-right: 1rem;
                padding: .875rem;

                &:last-child {
                    margin-right: 0;
                }

                .heading {
                    display: block;
                    font-size: 1.1rem;
                    font-weight: bold;
                }
                .description {
                    font-size: .875rem;
                    color: $gray-500;
                }
            }
        }

        .card-profile-actions {
            padding: .875rem;
        }

        @include media-breakpoint-down(xs) {
            .card-profile-actions {
                margin-top: 110px;
            } 
        }

        @include media-breakpoint-between(sm, md) {
            .card-profile-stats {
                margin-top: 30px;
            }
        }
    }
}

.card-footer{
    background-color:transparent;
}

// Card with blockquotes

.card {
    .card-blockquote {
        position: relative;
        padding: 2rem;

        .svg-bg {
            display: block;
            position: absolute;
            width: 100%;
            height: 95px;
            top: -94px;
            left: 0;
        }
    }
}

// Animated cards

.card-lift-hover {
    &:hover {
        transform: translateY(-20px);
        @include transition($transition-base);
    }
}


