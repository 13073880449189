/*
 * Breakpoint mixins to ease development conditions
 */
@mixin breakpoint( $point ) {
    @if $point == 'md' {
        @media screen and (max-width: $md-breakpoint) { @content; }
    }
    @else if $point == 'sm' {
        @media screen and (max-width: $sm-breakpoint) { @content; }
    }
}
