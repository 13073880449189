/**
 * = Backgrounds
 */

@each $color, $value in $colors {
	@include bg-variant(".bg-#{$color}", $value);
}

@each $color, $value in $theme-colors {
	@include bg-gradient-variant(".bg-gradient-#{$color}", $value);
}

@each $color, $value in $theme-colors { 
	@include bg-gradient-variant(".bg-gradient-#{$color}", $value);
}

// Backgrounds Overlay
@each $color, $value in $theme-colors {
    .overlay-#{$color}{ 
        &:before{
            position: absolute;
            content:"";
            background: $value;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0.7;
            z-index: 0;
            border-radius: inherit;
        }
    }
}

@each $color, $value in $theme-colors {
    @include bg-variant(".section-#{$color}", $value);
}

.bg-image {
    @include background-image(no-repeat, cover); 
}
.form-bg-image{
    @include back-image(no-repeat); 

    @include media-breakpoint-down(lg) {
        background-image: none !important;
    }
}
.bg-img-holder {
    position: absolute;
    height: 100%;
    min-height: 20rem;
    background-repeat: no-repeat;
    z-index: -1;
}
